import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import Animated from 'components/Animated'
import Image from 'components/Image'
import Layout from 'components/layout'
import SEO from 'components/seo'
import CurveWrapper from 'components/CurveWrapper'
import { H, Grid, Wrapper } from 'components/common'
import { BORDER, COLOR, GTR } from 'styles/tokens'

import Form from './Form'

const StyledHeroWrapper = styled(Wrapper)`
  background-color: ${COLOR.GREY.DARK};
  color: ${COLOR.WHITE};
`

const StyledList = styled.ul`
  margin-bottom: 0;

  li:not(:last-of-type) {
    margin-bottom: ${GTR.L};
  }
`

const StyledCard = styled(Animated)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.L};
  border-radius: ${BORDER.RADIUS.M};
  height: 100%;
`

const JoinOurTeam = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "pritesh-and-team-with-whiteboard.jpg" }
      ) {
        ...fluidImage
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Join us"
        description="We love what we do, if you have ambition to grow and are looking for your next recruitment job in London, get in touch."
      />
      <StyledHeroWrapper>
        <Wrapper container>
          <H size="XL" centered margin={[0, 0, 'XXL']}>
            Work at Pixel Pond
          </H>
          <Grid>
            <Grid.Item spanFromM={5}>
              <H size="L" color={COLOR.ACCENT.PRIMARY}>
                Why join our team?
              </H>
              <p>
                We’ve been growing pretty fast, so are wanting to expand our
                team. We’re specifically looking for Technology and Product
                recruiters of all levels, and it’s important that whoever joins
                the team shares our ambition to grow Pixel Pond and improve the
                industry. Joining us now means you’ll be able to grow with us,
                we’re committed to investing in our team.
              </p>
            </Grid.Item>
            <Grid.Item spanFromM={6} startColumnFromM={7}>
              <Image fluid={image.childImageSharp.fluid} />
            </Grid.Item>
          </Grid>
        </Wrapper>
      </StyledHeroWrapper>
      <CurveWrapper background={COLOR.ACCENT.PRIMARY} top={55}>
        <Wrapper container>
          <H size="L" centered margin={[0, 0, 'XL']}>
            Our core values
          </H>
          <Grid gapFromM="L">
            <Grid.Item spanFromM={6}>
              <StyledCard>
                <H size="M">Be open</H>
                <StyledList>
                  <li>Never feel you need to be anything but yourself.</li>
                  <li>
                    People in the know make better decisions and do better work,
                    so we try to ensure we all have access to as much
                    information about the industry as possible.
                  </li>
                  <li>
                    We have a culture of constructive and timely feedback from
                    all team members.
                  </li>
                </StyledList>
              </StyledCard>
            </Grid.Item>
            <Grid.Item spanFromM={6}>
              <StyledCard>
                <H size="M">Be helpful</H>
                <StyledList>
                  <li>
                    There isn’t just one valid point of view, so treat other
                    opinions with respect.
                  </li>
                  <li>
                    Everyone is new at some point, so take time to help anyone
                    in the team with less experience.
                  </li>
                  <li>
                    If there’s a charity or cause close to your heart, don’t
                    keep it to yourself. If we can, we’ll support it too.
                  </li>
                </StyledList>
              </StyledCard>
            </Grid.Item>
            <Grid.Item spanFromM={6}>
              <StyledCard>
                <H size="M">Be ambitious</H>
                <StyledList>
                  <li>
                    If you have big goals, reach for them and if you think we
                    can help you, we’ll always do what we can.
                  </li>
                  <li>
                    Finding success always means taking risks, and sometimes
                    messing up. This shouldn’t put you off, it’s all about how
                    you pick yourself up again.
                  </li>
                </StyledList>
              </StyledCard>
            </Grid.Item>
            <Grid.Item spanFromM={6}>
              <StyledCard>
                <H size="M">Be curious</H>
                <StyledList>
                  <li>
                    You are in charge of how good you can be, but we’re here to
                    support you anyway we can.
                  </li>
                  <li>Always look for a better way of doing things</li>
                  <li>
                    Make an effort to go to relevant events and conferences.
                  </li>
                </StyledList>
              </StyledCard>
            </Grid.Item>
          </Grid>
        </Wrapper>
      </CurveWrapper>
      <Form />
    </Layout>
  )
}

export default JoinOurTeam
