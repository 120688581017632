import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik, Form, useFormikContext } from 'formik'
import * as yup from 'yup'
import { Link, navigate } from 'gatsby'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import axios from 'axios'
import map from 'lodash/map'

import PATHS from 'routes/paths'
import { BORDER, COLOR, GTR } from 'styles/tokens'

import phoneNumberValidation from 'lib/validation/phone-number'

import { H, Grid, Wrapper } from 'components/common'
import Button from 'components/common/Button'
import Input from 'components/form/Input'
import Checkbox from 'components/form/Checkbox'
import FileUpload from 'components/FileUpload'

const JOIN_THE_TEAM_ZAP_URL =
  'https://hooks.zapier.com/hooks/catch/7432126/oigctcw'

const StyledBox = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.L};
  border-radius: ${BORDER.RADIUS.S};
  margin-bottom: ${GTR.L};
`

const ErrorSummary = styled(Wrapper)`
  background-color: ${COLOR.WHITE};
  padding: ${GTR.S};
  border-radius: ${BORDER.RADIUS.S};
  color: ${COLOR.STATE.ERROR};
  margin-bottom: ${GTR.L};
`

const InnerForm = ({ error }) => {
  const { errors, touched, isSubmitting } = useFormikContext()
  const visibleErrors = pick(errors, Object.keys(touched))
  const showErrorSummary = !isEmpty(visibleErrors)

  return (
    <Form>
      <H size="L" centered marginFromM={[0, 0, 'XL']}>
        Apply to join our team
      </H>
      <Grid>
        <Grid.Item spanFromL={8} startColumnFromL={3}>
          <StyledBox>
            <Grid columns={2}>
              <Grid.Item span={2}>
                <p>
                  <strong>
                    We’re currenlty only accepting applications from people who
                    are eligible to work in the UK.
                  </strong>
                </p>
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="firstName" label="First name" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="lastName" label="Last name" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="email" type="email" label="Email" />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="phoneNumber" label="Phone number" type="tel" />
              </Grid.Item>
              <Grid.Item span={2}>
                <FileUpload name="cv" label="CV" maxSize={2097152} />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input name="linkedInUrl" label="LinkedIn profile" optional />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input
                  name="currentNoticePeriod"
                  label="How long is your current notice period?"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input
                  name="desiredSalary"
                  label="What’s your desired salary?"
                />
              </Grid.Item>
              <Grid.Item span={2}>
                <Input
                  name="howDidYouHearAboutUs"
                  label="How did you hear about us?"
                  optional
                />
              </Grid.Item>
            </Grid>
          </StyledBox>
          <Grid>
            <Grid.Item spanFromM={6} startColumnFromM={4}>
              <Checkbox
                name="terms"
                label={
                  <>
                    I agree to the <Link to={PATHS.TERMS}>Terms of use</Link>{' '}
                    and <Link to={PATHS.PRIVACY}>Privacy Policy</Link>
                  </>
                }
              />
              <Wrapper centered margin={['XL', 0, 0]}>
                {error && <ErrorSummary>{error}</ErrorSummary>}
                {showErrorSummary && (
                  <ErrorSummary>
                    Please complete all required fields.
                  </ErrorSummary>
                )}
                <Button.Primary type="submit" stretch loading={isSubmitting}>
                  Submit application
                </Button.Primary>
              </Wrapper>
            </Grid.Item>
          </Grid>
        </Grid.Item>
      </Grid>
    </Form>
  )
}

InnerForm.propTypes = {
  error: PropTypes.string,
}

InnerForm.defaultProps = {
  error: '',
}

const schema = yup.object().shape({
  firstName: yup.string().required('This field is required.'),
  lastName: yup.string().required('This field is required.'),
  email: yup
    .string()
    .email('Please enter a valid email.')
    .required('This field is required.'),
  phoneNumber: yup
    .string()
    .test(...phoneNumberValidation)
    .required('This field is required.'),
  cv: yup.mixed().required('This field is required.'),
  currentNoticePeriod: yup.string().required('This field is required.'),
  desiredSalary: yup.string().required('This field is required.'),
  terms: yup.boolean().oneOf([true], 'This field is required.'),
})

const JoinOurTeamForm = () => {
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    setError(null)

    const formData = new FormData()
    map(values, (value, key) => formData.append(key, value))

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }

    try {
      await axios.post(JOIN_THE_TEAM_ZAP_URL, formData, config)
      navigate(PATHS.SUBMITTED)
    } catch (e) {
      // eslint-disable-next-line
      Sentry.captureException(e)
      setError(
        'There was an a problem submitting the form. Please try again, or send us an email at hello@pixel-pond.com.'
      )
    }
  }

  return (
    <Wrapper background={COLOR.BACKGROUND.PALE}>
      <Wrapper container>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            cv: null,
            linkedInUrl: '',
            currentNoticePeriod: '',
            desiredSalary: '',
            howDidYouHearAboutUs: '',
            terms: false,
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <InnerForm error={error} />
        </Formik>
      </Wrapper>
    </Wrapper>
  )
}

export default JoinOurTeamForm
