import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
}

const Animated = ({ children, className }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView()

  useEffect(() => {
    if (inView) {
      setTimeout(() => {
        controls.start('visible')
      }, 200)
    }
  }, [controls, inView])

  return (
    <motion.div
      className={className}
      variants={variants}
      initial="hidden"
      animate={controls}
      ref={ref}
    >
      {children}
    </motion.div>
  )
}

Animated.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Animated.defaultProps = {
  className: '',
}

export default Animated
